
import { useMeta } from 'vue-meta'
import Preloader from '@/views/components/Preloader.vue'
import { defineComponent } from 'vue'
import axios from 'axios'
import { Data } from '@/models/interfaces/MarketingPartners'

export default defineComponent({
  name: 'MarketingPartners',
  data: (): Data => {
    return {
      partners: []
    }
  },
  mounted () {
    this.getPartners()
  },
  methods: {
    getPartners (): void {
      if (process.env.VUE_APP_PARTNERS_URL) {
        axios.get(process.env.VUE_APP_PARTNERS_URL).then((response) => {
          this.partners = response.data
        })
      }
    }
  },
  setup () {
    useMeta({
      title: 'Marketing Partners'
    })
  },
  components: {
    Preloader
  }
})
